.App {
  text-align: center;
}

body {
  background-color: rgb(250, 250, 250);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card-shadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 6px -1px, rgba(0, 0, 0, 0.06) 0 2px 4px -1px;
}

.rv-treemap__leaf {
  cursor: pointer;
}

#radar-axis.rv-xy-plot__axis__line {
  stroke: rgba(112, 112, 112, 0.55) !important;
  stroke-width: 1;
}

#radar-text {
  fill: #6b6b76;
  stroke-width: 0.5 !important;
  font-size: 11px !important;
  font-weight: normal !important;
}

.rv-radar-chart-polygon[style*="stroke: rgba(225, 29, 72, 0.78);"] {
  stroke-width: 2 !important;
}

.rv-radar-chart-polygon[style*="stroke: rgba(150, 114, 240, 0.78);"] {
  stroke-width: 2 !important;
}

.rv-xy-plot__inner circle {
  cursor: pointer;
}

.rv-xy-plot__series--bar rect {
  cursor: pointer;
}